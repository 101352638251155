<template>
<div id="app">

    <b-loading v-model="connecting" :can-cancel="false" style="background-color: #fff">
        <div class="block">
            <b-icon icon="sync" size="is-large" type="is-primary">
            </b-icon>
        </div>
    </b-loading>

    <div class="content">

        <section id="display" class="hero is-primary  has-text-centered">
            <div class="hero-body">
                <b-icon size="is-small" :icon="is_heating ? 'radiator' : 'radiator-off'" :type="is_heating ? 'is-danger' : ''"></b-icon>

                <p class="title has-text-centered" v-if="temperature" @click="isImageModalActive = true">
                    {{temperature}}<small>°C</small>
                </p>

                <div class="columns is-mobile">
                    <div class="column is-10 is-offset-1">
                        <b-progress show-value format="percent" type="is-info" v-model="progress"></b-progress>
                    </div>
                </div>

            </div>
        </section>


        <section id="program-form" style="position:relative">
            <div class="box">

                <div class="columns is-mobile" v-for="(segment, index) in program" :key="index">
                    <div class="column is-one-third has-text-weight-light program-title">
                        {{segment[2]}}
                    </div>
                    <div class="column is-one-third">
                        <b-field>
                            <b-select placeholder="" v-model="segment[0]" :disabled="is_busy">
                                <option v-for="option in choices_time" :value="option" :key="option">
                                    {{ formatTime(option) }}
                                </option>
                            </b-select>
                        </b-field>
                    </div>
                    <div class="column is-one-third">
                        <b-field>
                            <b-select placeholder="" v-model="segment[1]" :disabled="is_busy">
                                <option v-for="option in choices_temp" :value="option" :key="option">
                                    {{ option }}°
                                </option>
                            </b-select>
                        </b-field>
                    </div>
                </div>
            </div>

        </section>


        <div class="box">

            <p class="control" v-if="is_busy" has-text-centered>
                <b-button label="Стоп" type="is-warning" @click="abort_program"  size="is-medium" />
            </p>
            <p class="control" v-else has-text-centered>
                <b-button label="Старт" type="is-primary" @click="start_program"  size="is-medium" />
            </p>

        </div>

    </div>

    <b-modal v-model="isImageModalActive">
        <p class="image">
            <iframe width="450" height="260" src="https://thingspeak.com/channels/1526644/charts/1?bgcolor=%23ffffff&color=%23d62020&dynamic=true&results=60&title=Temp&type=line"></iframe>
        </p>
    </b-modal>
</div>
</template>



<script>
import moment from 'moment'
export default {
    name: 'ThermostatApp',

    data() {
        return {
            connecting: true,
            temperature: null,
            progress: null,
            is_busy: false,
            is_heating: false,
            program: [],

            isImageModalActive: false,

            server: "ws://thermostat.local:80",

            choices_temp: this.generate_select_values(20, 60, 5), // min, max, step
            choices_time: this.generate_select_values(1800, 18000, 1800),
        }
    },

    methods: {
        generate_select_values(min, max, step) {

            let select = []
            for (let option = min; option < max; option += step) {
                select.push(option);
            }
            return select
        },
        formatTime: function(value) {
            return moment.utc(value * 1000).format('HH:mm');

        },
        process_message(data) {
            // console.log(data)
            switch (data.type) {

                case 'temperature':
                    this.temperature = data.value;
                    break;

                case 'temperature_expected':
                    break;

                case 'progress':
                    this.progress = data.value;
                    break;

                case 'program':
                    this.program = data.value;
                    console.log(this.program)
                    break;

                case 'is_busy':
                    this.is_busy = data.value
                    break;

                case 'is_heating':
                    this.is_heating = data.value
                    break;

                case 'time_elapsed':
                    break;

                case 'temp_expected':
                    break;

                default:
                    console.log(data);
            }
        },

        connect() {

            const vm = this
            console.log("Starting WebSocket connection")

            try {
                this.ws = new WebSocket(this.server)
            } catch (e) {
                return
            }

            this.ws.onmessage = function(event) {
                vm.process_message(JSON.parse(event.data))
            }

            this.ws.onopen = function(event) {
                vm.connecting = false;
                console.log(event)
                console.log("Connected")
            }

            this.ws.onclose = function() {
                vm.connecting = true;
                setTimeout(function() {
                    vm.connect();
                }, 1000);
            }

            this.ws.onerror = function() {
                vm.ws.close();
            }
        },

        form_to_program: function() {

        },

        start_program: function() {
            console.log(this.program)
            this.chart_expected = [],
                this.chart_real = [],
                this.ws.send(JSON.stringify({
                    action: 'start',
                    program: this.program
                }));

        },
        abort_program: function() {
            this.ws.send(JSON.stringify({
                action: 'abort'
                // action: 'stop'
            }));
        }
    },
    mounted: function() {
        this.connect()
    }
}
</script>

  <style lang="scss" scoped="">
  // Import Bulma's core
  @import "~bulma/sass/utilities/_all";

  // Set your colors
  $primary: #2f2682;
  $primary-light: findLightColor($primary);
  $primary-dark: findDarkColor($primary);
  $primary-invert: findColorInvert($primary);

  $warning: #e20811;
  $warning-light: findLightColor($primary);
  $warning-dark: findDarkColor($primary);
  $warning-invert: findColorInvert($primary);

  // Lists and maps
  $custom-colors: null !default;
  $custom-shades: null !default;

  // Setup $colors to use as bulma classes (e.g. 'is-twitter')
  $colors: mergeColorMaps(("white": ($white, $black,), "black": ($black, $white,), "light": ($light, $light-invert,), "dark": ($dark, $dark-invert,), "primary": ($primary, $primary-invert, $primary-light, $primary-dark,), "link": ($link, $link-invert, $link-light, $link-dark,), "info": ($info, $info-invert, $info-light, $info-dark,), "success": ($success, $success-invert, $success-light, $success-dark,), "warning": ($warning, $warning-invert, $warning-light, $warning-dark,), "danger": ($danger, $danger-invert, $danger-light, $danger-dark,),), $custom-colors);

  // Links
  $link: $primary;
  $link-invert: $primary-invert;
  $link-focus-border: $primary;

  $icon-dimensions: 0.5rem;

  $loading-background: rgba(255,255,255,1);

  @import "~bulma";
  @import "~buefy/src/scss/buefy";

  #app {
      font-family: Avenir, Helvetica, Arial, sans-serif;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;

        .program-title {
            line-height: 40px;
        }
      .loading-overlay .icon {
          animation: rotation 2s infinite linear;
        }
        @keyframes rotation {
          from {
            transform: rotate(359deg);
          }
          to {
            transform: rotate(0deg);
          }
        }
      .box,
      .columns section,
      .control,
      .hero {
          box-shadow: none;
          position: relative;
          transition: background-color 0.3s;
      }
      .title small {
          font-weight: 300;
      }
      section > .box:not(:last-child) {
        margin-bottom: 0;
      }
      .box .control {
          text-align: center;
      }

  }
</style>
