import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'


import Buefy from 'buefy'
import 'buefy/dist/buefy.css'

Vue.use(Buefy)


import TrendChart from "vue-trend-chart";
Vue.use(TrendChart);

Vue.config.productionTip = false

new Vue({
  render: function (h) { return h(App) },
}).$mount('#app')
